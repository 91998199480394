import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import List from "../CompanyList/List";
import MenuRecord from "../Record/MenuRecord";
import DateTime from "../Record/DateTime/DateTime";
import Service from "../Record/Service/Service";
import Staff from "../Record/Staff/Staff";
import Menu from "../Menu";
import Security from "../Security";
import axios from "axios";

import GlobalVariable from "../../GlobalVariable";


export class PointItem extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: false,
            service: false,
            dateTime: false,
            activeBlock: '',
            activeCompany: [],
            companyInfo: [],
            isLoad: false,
            auth: false,
            name: '',
            phone: '',
            record: [],
            star: []

        };


    }

    updateData = (value) => {
        this.setState({activeBlock: value})
    }
    updateAuth = (value) => {
        this.setState({auth: value})
    }
    updateMenu = (value) => {
        this.setState({activeBlock: value})
    }

    componentDidMount() {
        const data = {
            'activeCompanyCity': this.props.idCity,
            'activePoint': this.props.idPoint,
            'link': 'changeCompany'
        };

        let staff = '';
        let dateTime = '';
        let service = '';
        axios({

            url: GlobalVariable.host() + '/open/widget/booking/companies/city/info-point/?idPoint=' + this.props.idPoint,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                // if(result.data.points.link != null){
                //     window.open(result.data.points.link, '_blank');
                //     window.open("/" + this.props.widgetId + "/record/company-city/" + this.props.idCity);
                //
                // }
                console.log(this.state)
                this.setState({
                    companyInfo: result.data.points,
                    staff: result.data.status.staff,
                    service: result.data.status.service,
                    dateTime: result.data.status.dateTime

                })

                staff = result.data.status.staff;
                service = result.data.status.service;
                dateTime = result.data.status.dateTime;

            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })

        console.log(this.state)
    }

    renderButton() {
        if (!this.state.companyInfo.link) {
            if (this.state.staff && this.state.service && this.state.dateTime) {
                return (<Link to={'/' + this.props.widgetId + '/record/final/point/' + this.props.idPoint}>
                    <div className={'button'}>
                        Оформить визит
                    </div>
                </Link>)
            } else {
                return (

                    <div className={'button-false'}>
                        Оформить визит
                    </div>

                )
            }
        }
    }

    renderPointBlock() {
        if (this.state.companyInfo.link) {
            return (
                <div className={'point-block'}>
                    <a href={this.state.companyInfo.link} target={'_blank'}>
                        <div className={'button'}>
                            Перейти для записи
                        </div>
                    </a>
                </div>
            )

        } else {
            return (
                <div className={'point-block'}>

                    <Link
                        to={"/" + this.props.widgetId + "/record/company-city/" + this.props.idCity + "/point/" + this.props.idPoint + "/service"}>
                        <div className={'d-flex'}>
                            <img style={{width: '25px'}} className="mr-3" src="/img/Filter_Icon_UIA.png"/>
                            <div style={{marginLeft: '10px'}}>Услуга</div>
                            <div style={{width: '100%', justifyContent: 'flex-end'}} className={'d-flex'}>
                                <div style={{marginRight: '20px'}}><img className="mr-3"
                                                                        src={this.state.service ? "/img/Ellipse-true.png" : "/img/EllipseFalse.png"}/>
                                </div>
                            </div>
                        </div>

                    </Link>
                    <hr/>
                    <Link
                        to={"/" + this.props.widgetId + "/record/company-city/" + this.props.idCity + "/point/" + this.props.idPoint + "/date"}>
                        <div className={'d-flex'}>
                            <img style={{width: '25px'}} className="mr-3" src="/img/calendar.png"/>
                            <div style={{marginLeft: '10px'}}>Дата&nbsp;и&nbsp;время</div>
                            <div style={{width: '100%', justifyContent: 'flex-end'}} className={'d-flex'}>
                                <div style={{marginRight: '20px'}}><img className="mr-3"
                                                                        src={this.state.dateTime ? "/img/Ellipse-true.png" : "/img/EllipseFalse.png"}/>
                                </div>
                            </div>
                        </div>

                    </Link>
                    <hr/>
                    <Link
                        to={"/" + this.props.widgetId + "/record/company-city/" + this.props.idCity + "/point/" + this.props.idPoint + "/staff"}>
                        <div className={'d-flex'}>
                            <img style={{width: '25px'}} className="mr-3" src="/img/user-square.png"/>
                            <div style={{marginLeft: '10px'}}>Мастер</div>
                            <div style={{width: '100%', justifyContent: 'flex-end'}} className={'d-flex'}>
                                <div style={{marginRight: '20px'}}><img className="mr-3"
                                                                        src={this.state.staff ? "/img/Ellipse-true.png" : "/img/EllipseFalse.png"}/>
                                </div>
                            </div>

                        </div>

                    </Link>

                </div>
            )
        }
    }

    render() {

        return (
            <div className={''} id={'point-item'}>
                <div className={'top-block'} style={{}}>
                    <img className={'logoPoint'} style={{width: '100%', height: '100%', objectFit: "cover"}}
                         src={'/img/logo_point.png'}
                        // src={this.state.companyInfo.logo}
                         alt=""/>

                    <Link to={"/" + this.props.widgetId + "/record/company-city/" + this.props.idCity}>
                        <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                            <img style={{height: 'fit-content', margin: 'auto 0'}} className="mr-3"
                                 src="/img/arrow-left.png"/>
                            <div style={{marginLeft: '10px', color: 'white'}}>Выбрать другую точку</div>
                        </div>
                    </Link>
                    <div className={'header-block'}>
                        <div>
                            <div style={{justifyContent: 'space-between'}} className={'d-flex mb-2 '}>

                                <div>Чио Чио {this.state.companyInfo.city}</div>
                                <div className={'stars'}><img style={{width: '20px'}} className="mr-3 stars-img"
                                                              src="/img/stars-5.png"/></div>


                            </div>
                            <div style={{cursor: "pointer", marginBottom: '10px'}} className={'city-point'}>
                                <img className="mr-3" src="/img/pointMap.png"/>
                                <div style={{fontSize: '15px'}}>{this.state.companyInfo.address}</div>
                            </div>
                        </div>


                    </div>
                </div>
                {this.renderPointBlock()}

                {this.renderButton()}

                <Menu active={'record'} widgetId={this.props.widgetId}/>
            </div>

        )


    }
}

export default PointItem;